.error-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8em;
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.admin-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 2px;
  -webkit-box-shadow: 5px 5px 20px 0px #666;
          box-shadow: 5px 5px 20px 0px #666;
}
.admin-form img {
  width: 200px;
  display: block;
  border: none;
  margin: 0px auto;
}
.admin-form h2 {
  color: #009092;
  text-align: center;
  margin: 20px 0px;
  font-weight: 400;
}
.admin-form p {
  margin: 0px 0px 20px 0px;
}
.admin-form label {
  color: #009092;
  font-weight: 700;
  width: 100%;
}
.admin-form input {
  margin: 10px 0px 30px 0px;
  padding: 10px 5px;
  border: none;
  border: 1px solid #999 !important;
  background-color: transparent;
  color: #000;
  width: 100%;
  font-size: 0.9em;
}
.admin-form input:focus {
  outline: none;
  border-color: #009092 !important;
}
.admin-form button {
  display: block;
  width: 150px;
  margin: 10px auto !important;
}
.info-usuario {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  background-color: #fff;
  width: 600px;
}
.info-usuario h2 {
  color: #009092;
  margin: 20px 0px;
  font-weight: 400;
}
.info-usuario h3 {
  color: #009092;
  margin: 10px 0px;
  font-weight: 400;
  text-transform: capitalize;
}
.info-usuario p {
  margin: 10px 0px;
}
.usuarios-centro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  background-color: #fff;
  width: 850px;
}
.usuarios-centro.dermatologos{
  width: 1024px;
}
.usuarios-centro .usuario-asociado-lista {
  width: 100%;
}
.usuarios-centro .usuario-asociado-lista .usuario-asociado {
  display: table-row;
}
.usuarios-centro .usuario-asociado-lista .usuario-asociado .dato-usuario {
  display: table-cell;
  width: 20%;
  border-bottom: 1px solid #eee;
}
.usuarios-centro .usuario-asociado-lista .usuario-asociado .dato-usuario input:hover {
  cursor: pointer;
}
.usuarios-centro .usuario-asociado-lista .usuario-asociado.titulos .dato-usuario {
  font-weight: bold;
  padding-bottom: 10px;
  color: #009092;
}
.usuarios-centro .agregar-usuarios {
  width: 400px;
  margin: 0 auto;
  margin-top: 10px;
}
.usuarios-centro h2 {
  color: #009092;
  margin: 20px 0px 0px 0px;
  font-weight: 400;
}
.usuarios-centro h3 {
  color: #009092;
  margin: 20px 0px;
  font-weight: 400;
}
.usuarios-centro label {
  color: #009092;
  font-weight: 700;
  width: 100%;
}
.usuarios-centro input {
  margin: 10px 0px 10px 0px;
  padding: 10px 5px;
  border: none;
  border: 1px solid #999 !important;
  background-color: transparent;
  color: #000;
  width: 100%;
  font-size: 0.9em;
}
.usuarios-centro input
.usuarios-centro input:focus {
  outline: none;
  border-color: #009092 !important;
}
.usuarios-centro input[type="file"]{
  width: 80%;
}
.usuarios-centro button {
  display: block;
  width: 150px;
  margin: 10px auto !important;
}
.usuarios-centro .username {
  text-align: center;
  margin: 20px 0px;
}
.usuarios-centro .username h4 {
  color: #009092;
  margin: 0;
}
.usuarios-centro .username span {
  display: block;
  font-weight: 700;
  font-size: 1.8em;
  margin-top: 10px;
  color: #00bd92;
}
.cabecera {
  background-color: #009092;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  z-index: 2;
}
.cabecera h1 {
  height: 80px;
  width: 140px;
}
.cabecera .usuario {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
  margin-right: 20px;
  position: relative;
}
.cabecera .usuario p {
  color: #fff;
  margin: 0;
  font-size: 0.8em;
}
.cabecera .usuario figure {
  width: 30px;
  margin: 12px 0 0 10px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.cabecera .usuario figure:hover {
  cursor: pointer;
  opacity: 0.9;
}
.cabecera .usuario figure img {
  display: block;
  width: 100%;
}
.cabecera .usuario .logout {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  top: 100%;
  right: 0;
  width: 90%;
  min-width: 130px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2), 0px 0px 0px 1px rgba(0,0,0,0.1);
          box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2), 0px 0px 0px 1px rgba(0,0,0,0.1);
  display: none;
}
.cabecera .usuario .logout.show {
  display: block;
}
.cabecera .usuario .logout span {
  font-size: 0.9em;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  text-transform: uppercase;
  color: #009092;
}
.cabecera .usuario .logout span:hover {
  font-weight: bold;
  cursor: pointer;
}
.cabecera .filtros {
  -webkit-box-flex: 10;
      -ms-flex-positive: 10;
          flex-grow: 10;
  padding: 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cabecera .filtros .campo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 10px 0px 10px;
  min-width: 200px;
  width: 30%;
}
.cabecera .filtros .campo input {
  border: none;
  width: 100%;
  height: 25px;
  padding: 5px 10px;
  margin: 0;
  font-size: 0.8em;
}
.cabecera .filtros .campo span.btn-buscar {
  display: block;
  margin: 0;
  height: 35px;
  width: 50px;
  background-color: #c2dd7b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.cabecera .filtros .campo span.btn-buscar:hover {
  cursor: pointer;
  background-color: #99c032;
}
.cabecera .filtros .campo span.btn-buscar img {
  width: 20px;
}
.cabecera .filtros .campo select {
  width: calc(100% - 35px);
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0 50px 0 10px;
  font-size: 0.8em;
}
.cabecera .filtros .campo span.btn-dropdown {
  display: block;
  margin: 0;
  height: 35px;
  width: 35px;
  background-color: #c2dd7b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.cabecera .filtros .campo span.btn-dropdown:hover {
  cursor: pointer;
  background-color: #99c032;
}
.cabecera .filtros .campo span.btn-dropdown img {
  width: 15px;
}
.cabecera .filtros .campo .options-group {
  background-color: #fff;
  width: calc(100% - 35px);
  height: 25px;
  border: none;
  padding: 5px 10px;
  font-size: 0.8em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.cabecera .filtros .campo .options-group .options-list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #999;
}
.cabecera .filtros .campo .options-group .options-list .option {
  display: block;
  padding: 10px 10px;
}
.cabecera .filtros .campo .options-group .options-list .option:hover {
  background-color: #999;
  color: #fff;
  cursor: pointer;
}
.cabecera .filtros .campo .options-group.show .options-list {
  display: block;
}
.cabecera.admin {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.caso-detail-container {
  position: relative;
  width: 960px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
  min-height: 100vh;
}
.caso-detail-container.zoom {
  width: 90vw;
}
.caso-detail-container .caso-detail {
  width: 100%;
  margin-bottom: 50px;
}
.caso-detail-container .caso-detail h2 {
  color: #009092;
  font-size: 1.2em;
  text-transform: uppercase;
  margin: 0px 0px 20px 0px;
}
.caso-detail-container .caso-detail header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  border: none;
  padding: 0;
  background-color: #eee;
  height: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.caso-detail-container .caso-detail header span {
  color: #404040;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
}
.caso-detail-container .caso-detail header .caso-detail-back {
  width: 50px;
  height: 50px;
  background-color: #c2dd7b;
  background-image: url("/img/ico_back.png");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.caso-detail-container .caso-detail header .caso-detail-back.close {
  background-image: url("/img/ico_close.png");
}
.caso-detail-container .caso-detail header .caso-detail-back:hover {
  cursor: pointer;
  background-color: #99c032;
}
.caso-detail-container .caso-detail .info-caso .row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0px;
  border-bottom: 2px dashed #eee;
}
.caso-detail-container .caso-detail .info-caso .row .col {
  width: 50%;
  padding: 0px 20px;
}
.caso-detail-container .caso-detail .info-caso .row .col.full-width {
  width: 100%;
}
.caso-detail-container .caso-detail .info-caso .row .col .data {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0px;
}
.caso-detail-container .caso-detail .info-caso .row .col .data .key {
  color: #009092;
  width: 30%;
  max-width: 130px;
}
.caso-detail-container .caso-detail .info-caso .row .col .data .value {
  width: 70%;
}
.caso-detail-container .caso-detail .info-caso .row .col .data .value.highlight {
  font-weight: 700;
}
.caso-detail-container .caso-detail .info-caso .row .col .data .value .sintoma,
.sintomas-archivo .sintoma{
  background-color: #009092;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.8em;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.caso-detail-container .caso-detail .info-caso .row .col .data .value a {
  color: #404040;
}
.caso-detail-container .caso-detail .caso-images-container {
  padding: 20px 0px;
  position: relative;
  min-height: 240px;
  width: 100%;
  border-bottom: 2px dashed #eee;
}
.caso-detail-container .caso-detail .caso-images-container .caso-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.caso-detail-container .caso-detail .caso-images-container .caso-images figure {
  width: 240px;
  height: 240px;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #eee;
  position: relative;
  font-size: 4em;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #009092;
}
.caso-detail-container .caso-detail .caso-images-container .caso-images figure img {
  display: block;
  width: 100%;
  border: none;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  z-index: 1;
}
.caso-detail-container .caso-detail .caso-images-container .caso-images figure:hover {
  cursor: pointer;
}
.caso-detail-container .caso-detail .caso-images-container .caso-images figure:hover img {
  opacity: 0.8;
}
.caso-detail-container .caso-detail .caso-images-container .caso-images figure .zoom {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: url("/img/ico_zoom.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}
.caso-detail-container .caso-detail .zoom-detail figure {
  margin: 0;
  width: 100%;
}
.caso-detail-container .caso-detail .zoom-detail figure img {
  display: block;
  width: 100%;
}
.caso-detail-container .caso-detail .caso-interaction-container {
  padding: 20px;
  margin-bottom: 50px;
}
.caso-detail-container .caso-detail .caso-interaction-container .caso-textbox {
  width: calc(100% - 22px);
  resize: none;
  height: 200px;
  border-radius: 3px;
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #eee;
  font-size: 0.8em;
  line-height: 1.5em;
}
.caso-detail-container .caso-detail .caso-interaction-container .caso-textbox:focus {
  outline: none;
  border: 1px solid #eee;
}
.caso-detail-container .caso-detail .caso-interaction-container button {
  display: block;
  width: 150px;
  margin: 0px auto;
}
.caso-detail-container .caso-detail .caso-interaction-container .caso-interaction-options {
  margin: 0px 0px 20px 0px;
}
.caso-detail-container .caso-detail .caso-interaction-container .sending-mail {
  position: relative;
  width: 100%;
  height: 100px;
}
.caso-detail-container .caso-detail .caso-interaction-container .sending-mail h3 {
  text-align: center;
  color: #009092;
  font-weight: 400;
  margin: 0px 0px 30px 0px;
}
.caso-detail-container .caso-detail .caso-interaction-container .sending-mail .multi-spinner-container {
  position: relative;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: auto;
  left: 50%;
}
.caso-detail-container .caso-detail .historial-mensajes-container {
  padding: 20px;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  position: relative;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .delete-hito{
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 3px 10px;
  background-color: #f4f4f4;
  border-radius: 3px;
  font-size: 14px;
}

.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .delete-hito:hover{
  background-color: red;
  color: white;
  cursor: pointer;
}

.caso-detail-container .caso-detail .historial-mensajes-container .mensaje:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .mensaje-fecha {
  display: inline;
  font-size: 0.8em;
  font-weight: 400;
  color: #009092;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .mensaje-tipo {
  display: inline;
  font-size: 0.8em;
  padding: 3px 5px;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .mensaje-tipo.blue {
  background-color: #45a2ff;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .mensaje-tipo.green {
  background-color: #00bd92;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .mensaje-tipo.red {
  background-color: #ff9092;
}
.caso-detail-container .caso-detail .historial-mensajes-container .mensaje .mensaje-text {
  display: block;
  margin-top: 10px;
  font-size: 0.8em;
  line-height: 1.5em;
}
.casos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 40px);
  height: calc(100vh - 120px);
  padding: 0px 20px;
  z-index: 1;
}
.casos .col-casos {
  border-right: 1px dashed #999;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0px;
  min-width: 400px;
}
.casos .col-casos:last-child {
  border-right: none;
}
.casos .col-casos .titulo-col {
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  width: 150px;
  padding: 5px 0px;
  margin: 20px auto 20px auto;
  border-radius: 5px;
}
.casos .col-casos.cola .titulo-col {
  color: #ff9092;
}
.casos .col-casos.cola .caso {
  border-right-color: #ff9092;
}
.casos .col-casos.cola .caso .fecha {
  background-color: #ff9092;
}
.casos .col-casos.cola .caso .fecha .punta {
  border-top-color: #ff9092;
}
.casos .col-casos.proceso .titulo-col {
  color: #006aef;
}
.casos .col-casos.proceso .caso {
  border-right-color: #006aef;
}
.casos .col-casos.proceso .caso .fecha {
  background-color: #006aef;
}
.casos .col-casos.proceso .caso .fecha .punta {
  border-top-color: #006aef;
}
.casos .col-casos.finalizado .titulo-col {
  color: #00bd92;
}
.casos .col-casos.finalizado .caso {
  border-right-color: #00bd92;
}
.casos .col-casos.finalizado .caso .fecha {
  background-color: #00bd92;
}
.casos .col-casos.finalizado .caso .fecha .punta {
  border-top-color: #00bd92;
}
.casos .col-casos .casos-mensaje {
  min-width: 400px;
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.casos .col-casos .casos-mensaje p {
  font-weight: 400;
}
.caso {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  background-color: #fff;
  width: 85%;
  min-width: 320px;
  border-right-width: 5px;
  border-right-style: solid;
  -webkit-box-shadow: 5px 5px 10px 0px #999;
          box-shadow: 5px 5px 10px 0px #999;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  margin: 0 auto 40px;
}
.caso:hover {
  -webkit-box-shadow: 5px 5px 20px 0px #999;
          box-shadow: 5px 5px 20px 0px #999;
  cursor: pointer;
  -webkit-transform: translateX(-5px) scale(1.03);
          transform: translateX(-5px) scale(1.03);
}
.caso figure {
  width: 150px;
  height: 150px;
  margin: 0;
  overflow: hidden !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #eee;
}
.caso figure img {
  width: 100%;
}
.caso .image-placeholder {
  color: #009092;
  font-size: 4em;
}
.caso .image-placeholder.error {
  font-size: 0.8em;
}
.caso .info {
  position: relative;
  padding: 15px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.caso .info .info-paciente h3 {
  margin: 0;
  font-weight: 700;
}
.caso .info .info-paciente h4 {
  margin: 0;
  font-weight: 400;
  font-size: 0.9em;
}
.caso .info .info-doctor .nombre-doctor,
.caso .info .info-doctor .nombre-centro {
  color: #999;
  margin: 0;
  font-size: 0.8em;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-left: 20px;
}
.caso .info .info-doctor .nombre-doctor {
  background-image: url("/img/ico_doctor.png");
  margin-bottom: 7px;
}
.caso .info .info-doctor .nombre-centro {
  background-image: url("/img/ico_centro.png");
}
.caso .info .fecha {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.7em;
  margin: 0;
  color: #fff;
  width: 30px;
}
.caso .info .fecha .punta {
  position: absolute;
  top: 100%;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 5px solid #000;
}
.caso .info .fecha span {
  display: block;
  text-align: center;
}
.caso .info .fecha .dia {
  font-size: 1.5em;
}
.scrollarea {
  height: calc(100vh - 120px);
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.scrollarea .scrollbar-container.vertical {
  right: 5px !important;
  top: 5px !important;
}
.scrollarea .scrollbar-container.vertical .scrollbar {
  border-radius: 5px;
  width: 5px !important;
}
.caso-preview-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 3;
  background-color: rgba(255,255,255,0.8);
}
.caso-preview-container .caso-preview {
  z-index: 3;
  background-color: #fff;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
          box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.caso-preview-container .caso-preview .close-preview {
  background-color: #c2dd7b;
  padding: 10px;
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 3em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: -20px;
  right: -20px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.caso-preview-container .caso-preview .close-preview:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.08);
          box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.08);
  background-color: #99c032;
}
.caso-preview-container .caso-preview figure.logo-interderm {
  width: 200px;
}
.caso-preview-container .caso-preview h2 {
  color: #404040;
  font-weight: 300;
  font-size: 1.5em;
  margin-bottom: 30px;
}
.caso-preview-container .caso-preview .caso {
  -webkit-box-shadow: 0px 0px 0px 1px #eee;
          box-shadow: 0px 0px 0px 1px #eee;
  border-right-color: #ff9092;
}
.caso-preview-container .caso-preview .caso .fecha {
  background-color: #ff9092;
}
.caso-preview-container .caso-preview .caso .fecha .punta {
  border-top-color: #ff9092;
}
.caso-preview-container .caso-preview .caso:hover {
  -webkit-transform: none;
          transform: none;
  cursor: initial;
}
.caso-preview-container .caso-preview button {
  width: 50%;
}
header.home {
  background-color: #fff;
  position: relative;
}
header.home button {
  position: absolute;
  top: calc(50% - 5px);
  right: 20px;
  font-size: 0.8em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.content.home {
  background-image: url("/img/bg1.jpg");
  background-size: cover;
}
.content.home .greet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.content.home .greet p {
  background-color: #fff;
  display: inline-block;
  padding: 5px 10px;
  color: #009092;
  font-style: italic;
}
.content.home .greet figure {
  margin: 0;
  width: 300px;
  height: auto;
}
.content.home .greet nav {
  margin-top: 10px;
}
.multi-spinner-container {
  max-width: 150px;
  min-height: 150px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
          animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.login-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 2px;
  -webkit-box-shadow: 5px 5px 20px 0px #666;
          box-shadow: 5px 5px 20px 0px #666;
}
.login-container .login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
}
.login-container .login img {
  width: 200px;
  margin-bottom: 20px;
}
.login-container .login label {
  width: 100%;
}
.login-container .login input {
  margin: 10px 0px;
  padding: 10px 5px;
  border: none;
  border: 1px solid #999;
  background-color: transparent;
  color: #000;
  width: 100%;
  font-size: 0.9em;
}
.login-container .login input:focus {
  outline: none;
  border-color: $green;
}
.login-container .login button {
  margin-top: 20px;
}
.login-container .login .error-message {
  font-size: 0.8em;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
body {
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 300;
  color: #404040;
}
input:focus,
select:focus {
  border: 0;
  outline: none;
}
header {
  height: 60px;
  padding: 10px;
  width: 100vw;
}
header h1 {
  margin: 0 0 0 20px;
  width: 105px;
  display: inline-block;
}
header h1 a {
  display: inline;
  margin: 0;
}
header h1 a img {
  display: block;
  height: 100%;
}
button {
  background-color: #c2dd7b;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #fff;
  border-radius: 2px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  margin: 5px;
}
button:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.08);
          box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.08);
  background-color: #99c032;
}
.global-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-x: hidden;
  background-image: url("/img/bg_gris.jpg");
}
.global-container.login,
.global-container.no-header {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-image: url("/img/bg_verde.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.global-container .content {
  width: 100vw;
  height: calc(100vh - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
ul {
  list-style: none;
  padding: 0;
}

.archivos-paciente{
  padding: 20px;
  border-bottom: 2px dashed #eee;
}

.archivos-paciente .archivo-paciente-fecha{
  display: block;
  margin: 15px 0px;
  text-decoration: underline;
  color: #009092;
  font-weight: 400;
  cursor: pointer;
}

.archivo-paciente-modal{
  position: fixed;
  width: 70%;
  max-height: 90vh;
  min-height: 200px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 15px 30px rgba(0,0,0,0.5);
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
}

.archivo-paciente-modal .close-archivo-paciente-modal {
  background-color: #c2dd7b;
  padding: 10px;
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 3em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: -20px;
  right: -20px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.archivo-paciente-modal .close-archivo-paciente-modal:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.08);
          box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.08);
  background-color: #99c032;
}
.archivo-paciente-modal .caso-images-container {
  padding: 20px 0px;
  position: relative;
  min-height: 340px;
  width: 100%;
}
.archivo-paciente-modal .caso-images-container .caso-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.archivo-paciente-modal .caso-images-container .caso-images figure {
  width: 260px;
  height: 260px;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #eee;
  position: relative;
  font-size: 4em;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #009092;
}
.archivo-paciente-modal .caso-images-container .caso-images figure img {
  display: block;
  width: 100%;
  border: none;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  z-index: 1;
}
.archivo-paciente-modal .caso-images-container .caso-images figure:hover {
  cursor: pointer;
}
.archivo-paciente-modal .caso-images-container .caso-images figure:hover img {
  opacity: 0.8;
}
.archivo-paciente-modal .caso-images-container .caso-images figure .zoom {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: url("/img/ico_zoom.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}

.archivo-paciente-modal .zoom-detail-archivo{
  width: 100%;
  height: 90vh;
  cursor: zoom-out;
}

.archivo-paciente-modal .zoom-detail-archivo figure {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.archivo-paciente-modal .zoom-detail-archivo figure img {
  display: block;
  width: 100%;
}

.archivo-paciente-modal p{
  font-size: 16px;
}

.archivo-paciente-modal p span:not(.sintoma){
  color: #009092;
  font-weight: 400;
  min-width: 230px;
  display: inline-block;
}

.archivo-paciente-modal .sintomas-archivo{
  display: inline-block;
}

.archivo-paciente-modal .sintomas-archivo .sintoma{
  font-size: 14px;
}
